.footer {
  z-index: $_zindex-footer;
  text-align: center;

  background-color: #44524b;
  color: $_color-white;

  min-height: 320px;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  &-about {
    background-color: $_color-black;
    min-height: 48px;


    // @include media-breakpoint-up(lg) {
    //   display: block;
    // }

    p,
    a {
      color: $_color-white;
      @include fluid-type(15px, 15px);
    }

    a{
      display: inline-block;
    }
  }



}