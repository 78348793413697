/**
    Body

*/

body {
    // @include fluid-type(18px, 20px);
    @include fluid-type(16px, 19px);
    font-family: $_site-font-body;
    color: $_color-black;
}

/**
    Headers
*/

h1,
h2,
h3,
h4,
h5,
h6,
.site-h1,
.site-h2,
.site-h3,
.site-h4,
.site-h5,
.site-h6 {
    color: $_color-black;
    font-family: $_site-font-header;
    font-weight: bold;
    font-style: normal;
    margin-top: 15px;
    margin-bottom: 0;
    // text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.2;
}

h1,
.site-h1 {
    @include fluid-type(30px, 70px);
}

h2,
.site-h2 {
    @include fluid-type(24px, 48px);
}

h3,
.site-h3 {
    @include fluid-type(16px, 28px);
    font-weight: 700;
 }

h4,
.site-h4 {
  @include fluid-type(16px, 24px);
  font-weight: 700;
}

// h5,
// .site-h5 {
//     @include fluid-type(16px, 20px);
//     text-transform: uppercase;
// }

// h6,
// .site-h6 {
//     @include fluid-type(16px, 19px);
// }


/**
    Paragraph
*/

p,
.site-p {
    @include fluid-type(16px, 19px);
    /*line-height: 1.55 !important;*/
    /*font-weight: 300;*/
    margin-top: 15px;
    margin-bottom: 0;
    line-height: 1.58;

    a {
        text-decoration: underline;
    }

    small{
      @include fluid-type(12px, 12px);
      a{
        @include fluid-type(12px, 12px);
      }
    }
}

/**
    Links
*/

a,
.site-a,
.a-cta {
    font-family: $_site-font-body;
    // @include fluid-type(19px, 19px);
    @include fluid-type(16px, 19px);    
    /*font-weight: 500;*/
    /*line-height: 1.58;*/
    color: $_color-black;
    cursor: pointer;

    &:hover, &:active, &:focus {
        text-decoration: underline;
    }

    h1,
    h2,
    h3,
    h4{//,
    // h5 
    // {
        color: $_color-black;
        line-height: 1.2;

        &:hover, &:active, &:focus {
            text-decoration: underline;
        }
    }

}


/**
    Misc
*/

b, strong {
    /*font-weight: 700;*/
}

.b-header-font {
    font-family: $_site-font-header;
}

.b-body-font {
    font-family: $_site-font-body-font;
}


/*a.anchor {
    display: block;
    position: relative;
    top: -145px;
}*/

.site-tag {
  @include fluid-type(12px, 14px);
  font-family: $_site-font-header;
  font-weight: normal;
  letter-spacing: 6px;
  line-height: 1.8;
}