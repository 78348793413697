.btn {
  width: 100%;
  text-align: center;
  min-height: 54px;
  max-height: 54px;
  min-width: 125px;
  padding: 0 40px;
  line-height: 3.2;
  white-space: nowrap;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;

  &:hover,
  &:target,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  &.disabled {
    cursor: pointer;
    pointer-events: none;
  }

  &--inline {
    @include media-breakpoint-up(lg) {
      width: inherit;
    }
  }

  &--wide {
    padding: 0 100px;
  }

  /*Create ripple effect*/

  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);


  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
  }

  /*Create ripple effect*/

}

.btn-primary,
.btn-secondary {
  border: none;
  @include fixed-type(16);
  font-family: $_site-font-header;
  cursor: pointer;
  display: inline-flex;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.5s ease, border 0.5s ease;
}

.btn-primary {
  color: $_color-white;
  background-color: $_color-button-primary;
  border: 2px solid $_color-button-primary;
  background-position: center;

  &:hover,
  &:target {
    color: $_color-white;
    border: 2px solid darken($_color-button-primary, 10%);
    background: darken($_color-button-primary, 10%) radial-gradient(circle, transparent 1%, darken($_color-button-primary, 10%) 1%) center/15000%;
  }

  &--blue {
    background-color: $_color-button-blue;
    border: 2px solid $_color-button-blue;

    &:hover,
    &:target {
      border: 2px solid darken($_color-button-blue, 10%);
      background: darken($_color-button-blue, 10%) radial-gradient(circle, transparent 1%, darken($_color-button-blue, 10%) 1%) center/15000%;
    }
  }

  &--pink {
    background-color: $_color-button-pink;
    border: 2px solid $_color-button-pink;

    &:hover,
    &:target {
      border: 2px solid darken($_color-button-pink, 10%);
      background: darken($_color-button-pink, 10%) radial-gradient(circle, transparent 1%, darken($_color-button-pink, 10%) 1%) center/15000%;
    }

  }

  &--purple {
    background-color: $_color-button-purple;
    border: 2px solid $_color-button-purple;

    &:hover,
    &:target {
      border: 2px solid darken($_color-button-purple, 10%);
      background: darken($_color-button-purple, 10%) radial-gradient(circle, transparent 1%, darken($_color-button-purple, 10%) 1%) center/15000%;
    }

  }

  &--green {
    background-color: $_color-button-green;
    border: 2px solid $_color-button-green;

    &:hover,
    &:target {
      border: 2px solid darken($_color-button-green, 10%);
      background: darken($_color-button-green, 10%) radial-gradient(circle, transparent 1%, darken($_color-button-green, 10%) 1%) center/15000%;
    }

  }


}

.btn-secondary {
  color: $_color-white;
  background-color: $_color-button-secondary;
  border: 2px solid $_color-button-secondary;

  &:hover,
  &:target,
  &:active,
  &:focus {
    color: $_color-white;
    border: 2px solid darken($_color-button-secondary, 10%);
    background-color: darken($_color-button-secondary, 10%);
  }
}