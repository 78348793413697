$_color-white: #fff;
$_color-black: #202020;

$_color-breaker-bay: #5eaa94;
$_color-bright-magenta: #ff00ff;
$_color-very-light-pink: #fcdfd8;
$_color-pickled-bluewood: #2a3d45;
$_color-nandor: #013d19;
$_color-zanah: #DBEEDE;

$_color-button-primary: $_color-breaker-bay;
$_color-button-secondary: $_color-pickled-bluewood;

$_color-hr: #C9C9C9;

$_color-linear-gradient-purple: linear-gradient(121deg, #3a2d72, #251e41);
$_color-linear-gradient-pink: linear-gradient(to left, #c790b5, #905992);
$_color-linear-gradient-green: linear-gradient(to left, #9ac790, #639259);
$_color-linear-gradient-blue: linear-gradient(to left, #90c5c7, #596392);

$_color-button-purple: #412e80;
$_color-button-green: #3d6935;
$_color-button-pink: #9b649a;
$_color-button-blue: #596393;


.site-color {
  &--white {
    color: $_color-white !important;
  }

  &--black {
    color: $_color-black !important;
  }
  
  &--pink {
    color: $_color-bright-magenta !important;
  }  
}

.site-bgcolor {
  &--black {
    background-color: $_color-black !important;
  }

  &--white {
    background-color: $_color-white !important;
  }

  &--pink {
    background-color: $_color-bright-magenta !important;
  }

  &--green{
    background-color: $_color-zanah !important;
  }
}
