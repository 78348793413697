.block {
  &-image-text {
    margin-top: 40px;

    @include media-breakpoint-up(lg) {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--left{
      @include media-breakpoint-up(lg) {
        justify-content: left;
      }
    }

    img {
      position: relative;
      width: calc(100% + 30px);
      height: auto;
      left: -15px;

      @include media-breakpoint-up(md) {
        left: inherit;
        height: inherit;
        width: 450px;
      }
    }

    &__button {
      width: 54px !important;
      height: 54px !important;
      margin-left: 0 !important;
      transition: transform 0.2s;

      left: 0 !important;

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.1);
      }

      &-link {
        @include font-size(20px);
        text-decoration: none;
        font-weight: 800;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include media-breakpoint-down(sm) {
          @include font-size(18px);
        }        

        span {
          display: inline-block;
          margin-left: 20px;

          @include media-breakpoint-down(sm) {
            display: inline-block;
            max-width: 260px;
          }          
        }

        img {
          margin: 0 !important;
        }

        
        &--white{
          &:hover,
          &:focus,
          &:active {
            color: $_color-white;
          }

          span{
            color: $_color-white;
          }
        }

      }

    }

    &--right {
      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;

        img {
          margin-left: 60px;
        }
      }
    }

    &--left {
      @include media-breakpoint-up(lg) {
        img {
          margin-right: 60px;
        }
      }
    }
  }

  &-qa {
    margin-top: 40px;

    // @include media-breakpoint-up(lg) {
    //   margin-top: 60px;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    // }

    &-col {
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-start;

        div {
          flex-basis: calc(50% - 15px);
        }
      }
    }

    &__button {
      // width: 54px !important;
      // height: 54px !important;
      margin-left: 0 !important;
      transition: transform 0.2s;

      left: 0 !important;

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.1);
      }


      &-link {
        @include font-size(20px);
        text-decoration: none;
        font-weight: 800;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include media-breakpoint-down(sm) {
          @include font-size(18px);
        }  

        span {
          display: inline-block;
          margin-left: 20px;

          @include media-breakpoint-down(sm) {
            display: inline-block;
            max-width: 260px;
          }             
        }

        img {
          margin: 0 !important;
        }
      
      }
    }

    &__line {
      position: relative;

      @include media-breakpoint-up(lg) {
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 25px;
          height: 85%;
          width: 2px;
          background-color: $_color-hr;
        }
      }
    }
  }

  &-image-subscribe {
    margin-top: 40px;

    @include media-breakpoint-up(lg) {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      position: relative;
      width: calc(100% + 30px);
      height: auto;
      left: -15px;

      @include media-breakpoint-up(md) {
        left: inherit;
        height: inherit;
        width: 400px;
      }
    }

    &--left {
      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;

        img {
          margin-left: 60px;
        }
      }
    }

    &--right {
      @include media-breakpoint-up(lg) {
        img {
          margin-right: 60px;
        }
      }
    }
  }

  &-info {
    @include media-breakpoint-up(lg) {
      position: absolute;
      width: 290px;
      top: -200px;
      right: 0;
    }

    @include media-breakpoint-up(xl) {
      right: 45px;
    }

    &-inner {
      padding: 20px 20px 15px 20px;
      background-color: $_color-pickled-bluewood; //$_color-nandor;

      h2,
      h3,
      h4,
      p,
      strong {
        color: $_color-white;
      }

      p, strong{
        @include fluid-type(14px, 16px);
      }

      strong{
        text-transform: uppercase;
        font-family: $_site-font-header;          
        letter-spacing: 4px;
      }
    }
  }
}
