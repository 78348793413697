﻿.section {
  &-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: $_color-linear-gradient-purple;
    color: $_color-white;
    min-height: 600px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      min-height: 800px;
      text-align: left;
    }

    &--purple {
      background-image: $_color-linear-gradient-purple;
    }

    &--blue {
      background-image: $_color-linear-gradient-blue;
    }

    &--green {
      background-image: $_color-linear-gradient-green;
    }

    &--pink {
      background-image: $_color-linear-gradient-pink;
    }

    &-icon {
      @include media-breakpoint-up(lg) {
        min-height: 100px;
      }
    }

    &__icon {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 1;

      transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;

      @include media-breakpoint-up(lg) {
        img {
          width: 85px;
          height: 85px;
        }
      }

      &:hover,
      &:active,
      &:focus {
        transform: scale(1.05);
      }

    }

    &-hero {
      position: relative;
      margin-bottom: 100px;
      
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left;
      background-position-x: 50%;
      background-position-y: 50%;

      @include media-breakpoint-up(lg) {
        min-height: 500px;
        margin-bottom: 150px;
      }

      h1 {
        font-weight: normal;
        color: $_color-white;
      }

      h2 {
        color: $_color-white;
        text-transform: uppercase;
        letter-spacing: 7.06px;
        @include fluid-type(12px, 16px);
      }

      &-cta {

        &-holder {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }

        position: absolute;
        background-color: #f1f8ef;
        padding: 30px 40px;
        width: calc(100% - 30px);
        color: $_color-black;
        bottom: -70px;
        left: 15px;

        @include media-breakpoint-up(lg) {
          width: 475px;
          right: 0;
          left: inherit;
        }

        &--pink {
          background-color: #f9f1f8;
        }

        &--green {
          background-color: #f1f8ef;
        }

        &--blue {
          background-color: #f2f5f7;
        }

      }
    }
  }

  &-top-scrolldown {
    position: relative;

    @include media-breakpoint-down(lg) {
      //height: 100vh;
      height: calc(100vh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)));
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 100px;
    }

    &-holder {
      @include media-breakpoint-only(lg) {
        padding-bottom: 150px;
      }
    }

    &__arrow {
      position: absolute;
      left: calc(50% - 27px);
      transition: bottom ease-in-out .3s;

      @include media-breakpoint-up(lg) {
        bottom: -200px;
      }


      &:hover,
      &:focus,
      &:active {

        @include media-breakpoint-up(lg) {
          bottom: -210px;
        }
      }
    }

    &__icon {
      transition: transform 0.2s ease-in-out;

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.1);
      }
    }

    &__stroke {
      position: relative;

      @include media-breakpoint-up(lg) {
        padding-left: 50px;
      }

      &:before {
        display: none;
        content: "";
        position: absolute;
        top: 0;
        left: -50px;
        background-image: url('~/src/_includes/images/letscocreate/stroke.svg');
        width: 100%;
        height: 360px;
        background-repeat: no-repeat;
        background-position: top left;


        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    h2 {
      color: $_color-white;
      text-transform: uppercase;
      letter-spacing: 7.06px;
      @include fluid-type(12px, 16px);
    }

    h1 {
      @include fluid-type(47px, 70px);
      line-height: 1.21;

      @include media-breakpoint-up(lg) {
        line-height: 1.41;
      }
    }
  }

  &-top-subpage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-image: linear-gradient(to bottom, #07192b, #2d3f51);

    padding-top: 65px;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      min-height: 500px;
    }

    &__back-arrow {
      position: absolute;
      left: 10px;
      top: 15px;
      font-weight: bold;
      text-decoration: none;

      @include media-breakpoint-up(lg) {
        left: 20px;
        top: 20px;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none !important;
      }

      span {
        position: relative;
        top: 2px;
      }
    }

    &--small {
      min-height: 230px;

      @include media-breakpoint-up(lg) {
        min-height: 360px;
      }
    }
  }

  &-middle {
    margin-top: 60px;

    &--bg-blue-gradient {
      background-image: linear-gradient(to bottom, #07192b, #2d3f51);

      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        color: $_color-white;
      }
    }

    &--bg-gray {
      background-color: #faf9f8;
    }

    &--bg-pink {
      background-color: $_color-very-light-pink;
    }

    &--bg-zanah {
      background-color: $_color-zanah;
    }

    &--bg-charcoal {
      background-color: $_color-pickled-bluewood;

      h1,
      h2,
      h3,
      h4,
      h5,
      p,
      a {
        color: $_color-white;
      }
    }

    &--bg-turquoise {
      background-image: linear-gradient(111deg, #ade8e4 25%, #75ccc4 84%, #75ccc4 84%);
    }

    &--bg-gradient-purple {
      background-image: $_color-linear-gradient-purple;
    }

    &--bg-gradient-blue {
      background-image: $_color-linear-gradient-blue;
    }

    &--bg-gradient-green {
      background-image: $_color-linear-gradient-green;
    }

    &--bg-gradient-pink {
      background-image: $_color-linear-gradient-pink;
    }

    &--bg-image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;

      @include media-breakpoint-down(lg) {
        background-image: none !important;
      }

      .block-image-text {
        @include media-breakpoint-up(lg) {
          max-width: 475px;
        }
      }
    }

    &--bg-cover {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left;
      background-position-x: 50%;
      background-position-y: 50%;
      min-height: 180px;

      @include media-breakpoint-only(md) {
        min-height: 300px;
      }

      @include media-breakpoint-up(lg) {
        min-height: 600px;
      }
    }
  }
}